import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// import PrivateRoute from "./utils/PrivateRoute";
import { UserConsumer } from "./store/UserProvider";
import Dashboard from "./views/dashboard";
import Auth from "./views/auth";
import Landing from "./views/landing";
import Contact from "./views/contact";

function App() {
  return (
    <Router>
      <UserConsumer>
        {({ currentUser }) => (
          <Switch>
            <Route exact path="/" component={currentUser ? Dashboard : Landing} />
            <Route exact path="/contact" component={Contact} />
            <Route path="/auth" component={Auth} />
          </Switch>
        )}
      </UserConsumer>
    </Router>
  );
}

export default App;
