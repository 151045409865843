import React from "react";
import { Link } from "react-router-dom";

import "./styles.scss";
import LandingPageImage from "../../assets/illustrations/landing-page-hero.png";
import PaisaaaLogo from "../../assets/illustrations/paisaaa-logo.png";

export default function Landing() {
  return (
    <div className="landing-background">
      <Link to="/contact" className="text-white fixed right-0 top-0 mr-10 mt-10 border rounded py-4 px-6">
        Request Early Access
      </Link>
      <div className="flex w-11/12 justify-between items-center mx-auto">
        <div className="w-1/2">
          <img
            src={LandingPageImage}
            className="landing-image"
            alt="Landing Page"
          />
        </div>
        <div className="w-1/2 flex flex-col justify-center items-center text-justify paisa-text-color">
          <img src={PaisaaaLogo} alt="Paisaaa Logo" />
          <div className="w-1/2 mx-auto mt-16">
            <p className="text-2xl">
              Keeping track of your expenses, income and lending is now easy.
            </p>
            <p className="text-2xl">
              Paisaaa is a one stop solution for your financial management. With
              features like group expenses, reminders and integrated chat
              Paisaaa helps you make the most of your money!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
