import React from "react";
import { Link } from "react-router-dom";

export default function Contact() {
  return (
    <div>
      <Link className="text-indigo-800 hover:text-indigo-400 px-2 py-4" to="/">
        ← Go Back
      </Link>
      <iframe
        title="Access Form"
        className="w-full h-screen"
        src="https://chirax.typeform.com/to/RkeFe7"
      />
    </div>
  );
}
