import logo from "../../logo.svg";
import React from "react";
import { UserConsumer } from "../../store/UserProvider";

export default function Auth() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Pls Login.</p>
        <UserConsumer>
          {({ onLogin }) => <button onClick={onLogin}>Login</button>}
        </UserConsumer>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}
