import React, { useState } from "react";

const { Provider, Consumer } = React.createContext();

const UserProvider = (props) => {
  const user = JSON.parse(window.localStorage.getItem("currentUser")) || null;
  const [currentUser, setCurrentUser] = useState(user);

  const onLogin = () => {
    // Some network request
    const user = {
      name: "Aditya Verma",
      access: "Bearer adsfq3w4er32q4qrwqefcqwefweqrqwerdsafvq3rqwer",
    };
    setCurrentUser(user);
    window.localStorage.setItem("currentUser", JSON.stringify(user));
  };

  const onLogout = () => {
    setCurrentUser(null);
    window.localStorage.removeItem("currentUser");
  };

  return (
    <Provider
      value={{
        currentUser,
        onLogin,
        onLogout,
      }}
    >
      {props.children}
    </Provider>
  );
};

export { UserProvider, Consumer as UserConsumer };
